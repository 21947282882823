<template>
  <div  v-if="!isTrialsView" class="protocols-widget">

    <table>
      <thead>
        <tr>
          <th>BPE</th>
          <th>Protocole</th>
          <th v-if="name === 'protocols'">Essais</th>
          <th v-if="name === 'protocols'">Thématique</th>
          <th v-else>Entité</th>
          <th>Sous-thème</th>
          <th>Culture</th>
          <th v-if="isUnion"></th>
        </tr>
      </thead>

      <tbody>
        <template v-if="protocols.length">
          <template v-for="(protocol, index) in protocols" :key="index">
            <tr>
              <td>
                <SvgIcon v-if="protocol.bpe" name="bpe" size="md" />
              </td>
              <td>
                <router-link v-if="name === 'protocols'" :to="{ name: 'protocol', params: { id: protocol.id } }"
                  v-ripple>
                  {{ protocol.titre }}
                </router-link>
                <router-link v-else :to="{ name: 'protocol', params: { id: protocol.id } }" v-ripple>
                  {{ protocol.titre }}
                </router-link>
              </td>
              <td v-if="name === 'protocols'" class="cell-counter">
                {{ protocol.nombre_essai }}
              </td>
              <td v-if="name === 'protocols'">{{ protocol.thematique.designation }}</td>
              <td v-else>{{ protocol.entite_parent.nom }}</td>
              <td>{{ protocol.sous_thematiques.map(sub => sub.nom).join(', ') }}</td>
              <td>{{ protocol.cultures.map(culture => culture.nom).join(', ') }}</td>
              <td v-if="isUnion" class="col-info-btn">
                <Btn v-if="protocol.nombre_essai > 0" color="primary" icon="info-circle" round size="lg" @click="showTrials(protocol.id)" />
                <Btn v-else color="primary" icon="info-circle" round size="lg" disabled />
              </td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="5" class="td-empty-message"> Aucun protocole n'a été trouvé</td>
          </tr>
        </template>

      </tbody>
    </table>

  </div>
  <template v-else>
      <div class="widget-navigation">
        <Btn color='primary' class="back-btn" round grow icon="chevron-big-left" @click="toggleIsTrials()"/>
        <h3 class="widget-navigation--title">
          Essais pour&nbsp;: {{ protocole.titre }}
        </h3>
      </div>
      <div class="protocols-widget">
        <table>
      <thead>
        <tr>
          <th>Désignation</th>
          <th>Entité</th>
          <th>Numéro</th>
          <th class="cell-validation">Validation agro</th>
        </tr>
      </thead>

      <tbody>
        <template v-if="protocole.essais.length">
          <template v-for="(trial, index) in protocole.essais" :key="index">
            <tr>
              <td>
                <template v-if="entity_id !== trial.protocole?.entite?.id">{{ trial.nom }}</template>
                <router-link v-else :to="{ name: 'essai', params: { id: trial.id } }" v-ripple>
                  {{ trial.nom }}
                </router-link>
              </td>
              <td>
                {{ trial.protocole.entite.nom }}
              </td>
              <td class="cell-number">
                {{ trial.numero ?? '—' }}
              </td>
              <td class="cell-validation" :class="trial.validation_class">
                <SvgIcon v-if="trial.validation_agro" name="check-circle" size="lg"/>
                <SvgIcon v-else name="times-circle" size="lg"/>
              </td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="5" class="td-empty-message"> Aucun essai n'a été trouvé</td>
          </tr>
        </template>

      </tbody>
    </table>
      </div>

    </template>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'ProtocolsWidget',
  components: {
    SvgIcon,
    Btn,
  },

  props: {
    name: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: String,
      default: undefined,
    },
    isUnion: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      protocole: {},
      protocols: [],
      filters: '',
      isTrialsView: false,
      trialsFilters: {},
      entity_id: this.$store.state.auth.user.data.entite.id,
    }
  },

  emits: ['widget-emits-title', 'widget-emits-link'],

  watch: {
    modelValue() {
      this.paramsReceived(this.modelValue)
    },
  },

  mounted() {
    this.paramsReceived(this.modelValue)
  },

  methods: {
    toggleIsTrials() {
      this.isTrialsView = !this.isTrialsView
      this.$emit('widget-emits-link', { url: 'protocols', text: 'Voir tous les protocoles', widget: 'protocols' })
    },

    async showTrials(pId) {
      const index = this.protocols.findIndex((protocol) => protocol.id === pId)
      this.protocole = this.protocols[index]

      this.protocole.essais = await this.getEssais(pId)

      this.protocole.essais.forEach(async (essai, i) => {
        const isAgro = await this.getValidation(essai.id)
        const vClass = isAgro ? 'color--success' : 'color--error'
        this.protocole.essais[i].validation_agro = isAgro
        this.protocole.essais[i].validation_class = vClass
      })

      this.isTrialsView = true
      this.$emit('widget-emits-link', { url: 'essais', text: 'Voir tous les essais', widget: 'protocols' })
    },

    async getValidation(id) {
      const response = await this.fetchService.get(`essai/${id}/fiche/validation`, null, false)
      const datas = await response.data
      const fiche = datas.pop()
      const isAgro = fiche?.validation_agro ?? false
      return isAgro
    },

    async getProtocols() {
      const response = await this.fetchService.get('protocole', this.filters, false)
      const datas = await response.data
      return datas
    },

    async getProtocolsShared() {
      const response = await this.fetchService.get('protocole/partage', this.filters, false)
      const datas = await response.data
      return datas
    },

    async getEssais(pId) {
      let { filters } = this.filters
      const aFilters = filters.split('|n|')
      aFilters.forEach((value, index) => {
        aFilters[index] = `protocole.${value}`
      })
      filters = aFilters.join('|n|')
      this.trialsFilters.filters = filters
      this.trialsFilters.sort = 'nom.ASC'
      this.trialsFilters.limit = 0
      const response = await this.fetchService.get(`tableau-de-bord/protocole/${pId}`, this.trialsFilters, false)
      const datas = await response.data
      return datas
    },

    async paramsReceived(event) {
      this.filters = {
        limit: 0,
        sort: 'titre.ASC',
      }
      if (event) {
        this.filters.filters = event
      }

      if (this.name === 'protocols') {
        this.protocols = await this.getProtocols()
        this.$emit('widget-emits-title', { title: `${this.protocols.length} protocole(s)`, widget: 'protocols' })
      } else {
        this.protocols = await this.getProtocolsShared()
        this.$emit('widget-emits-title', { title: `${this.protocols.length} protocole(s) proposé(s) au  partage`, widget: 'protocolsShared' })
      }
    },
  },

}
</script>

<style lang="scss" scoped>
/* USERS WIDGET */

.protocols-widget {
  padding: $gutter-half;

  table {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    cell-spacing: 0;
    @include h-margin(- $gutter-quarter);
    line-height: $line-height-small;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: +1;
    font-weight: $font-weight-bold;
    background-color: white;

    .widget--color-primary & {
      background-color: $color-primary-lighty;
    }

    th {
      border-bottom: 1px solid $color-gray-lighter;

      .widget--color-primary & {
        border-color: $color-primary-light;
      }
    }
  }

  tbody {
    // overflow-y: auto;
  }

  td,
  th {
    padding: ($gutter-quarter * 1.5) $gutter-quarter;
    text-align: left;

    a {
      .widget--color-primary & {
        color: white;
        text-decoration: underline;
      }
    }

    &.td-empty-message {
      padding: $gutter-half;
      font-style: italic;
    }
  }

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px solid $color-gray-lighter;

        .widget--color-primary & {
          border-color: $color-primary-light;
        }
      }
    }
  }

  td.col-info-btn {
    > .btn {
      @include shadow(0);
    }
  }
}

.cell-counter {
  text-align: center !important;
  font-weight: $font-weight-bold;
  font-size: $font-size-big;
}

.widget-navigation {
  background-color: $color-primary-lighter;
  display: flex;
  align-items: center;
  padding: $gutter-quarter $gutter-half;
  padding-left: $gutter-quarter * 3;
  color:  $color-primary;
  border-bottom: 1px solid $color-primary-lighter;
}

.cell-validation {
  text-align: center !important;
}

.cell-number {
  font-family: $font-family-monospace;
}

.color--error{
  color: white;
}

.color--success{
  color: white
}

.widget-navigation--title {
  margin: 0
}
</style>
