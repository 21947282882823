<template>
  <div class="users-widget">

    <div class="user-stats user-stats--header">
      <div class="user-stats-user">Utilisateur</div>
      <div class="user-stats-counter">Essais</div>
    </div>

    <div
      class="user-stats"
      v-for="(user, index) in users"
      :key="index"
    >
      <div class="user-stats-user">
        <router-link :to="{ name: 'user', params: {userid: user.id} }" class="" v-ripple>
          <UserID
            :userNameFirstName="user.prenom"
            :userNameLastName="user.nom"
            :userAvatarImage="user.avatar"
            userAvatarSize="small"
            :userAvatarColor="user.couleur"
          />
        </router-link>
      </div>
      <div class="user-stats-counter">
        <Counter :value="user.nombre_essai" />
      </div>
    </div>

  </div>
</template>

<script>
import UserID from '@/components/user/UserID.vue'
import Counter from '@/components/base/Counter.vue'

export default {
  name: 'UsersWidget',

  components: {
    UserID,
    Counter,
  },

  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      users: [],
      filters: {
        limit: 0,
      },
      essai_filters: null,
    }
  },

  // TODO : Filter api
  watch: {
    modelValue() {
      this.paramsReceived(this.modelValue)
    },
  },

  mounted() {
    if (this.modelValue) {
      this.paramsReceived(this.modelValue)
    }
  },

  methods: {
    async getUsers() {
      let endpoint = 'utilisateur'
      if (this.essai_filters) {
        endpoint = `utilisateur?essai_filters=${this.essai_filters}`
      }
      const response = await this.fetchService.get(endpoint, this.filters, false)
      const datas = await response.data
      const users = datas.filter((user) => user.actif === true)
      return users
    },
    async paramsReceived(event) {
      this.filters = {
        limit: 0,
        sort: 'prenom.ASC,nom.ASC',
        filters: `entite.id:in(${this.$store.state.auth.user.data.entite.id})`,
      }

      if (event) {
        this.essai_filters = event
      }

      this.users = await this.getUsers()

      if (this.users) {
        this.$emit('widget-emits-title', {
          title: `${this.users.length} utilisateur(s)`,
          widget: 'users',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* USERS WIDGET */

.users-widget {
  padding: $gutter-half;
}

.user-stats {
  display: flex;
  gap: $gutter;

  &:not(:last-child) {
    margin-bottom: $gutter-half;
  }
}

.user-stats--header {
  font-weight: $font-weight-bold;
  color: $color-gray-light;

  .user-stats-counter {
    text-align: center;
  }
}

.user-stats-user {
  flex-grow: 1;
}

.user-stats-counter {
  flex-basis: 5rem;
  flex-shrink: 0;
}
</style>
