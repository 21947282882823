<template>
  <div class="map-widget">
    <div class="map" id="map"></div>
    <Btn class="btn-map-fullscreen" color="white" icon="expand" round @click="openModal('fullModal')"
      title="Plein écran" />
  </div>

  <Modal title="Localisation des essais" :active="modal.fullModal" @modal-close="closeModal('fullModal')" size="full"
    no-padding>
    <template v-slot:modal-body>
      <div class="map" id="modal-map"></div>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Fermer" @click="closeModal('fullModal')" />
    </template>
  </Modal>

</template>

<script>
import L from 'leaflet'
import { MarkerClusterGroup } from 'leaflet.markercluster/src'
import Modal from '../layout/Modal.vue'
import Btn from '../base/Btn.vue'

export default {
  name: 'MapWidget',

  components: {
    Modal,
    Btn,
  },

  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
    isUnion: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      endpoint: 'tableau-de-bord/map',
      essais: [],
      map: null,
      markers: [],
      positions: [],
      center: [46.747, 3.999],
      filters: {
        limit: 0,
      },
      modal: {
        fullModal: false,
      },
      entity_id: this.$store.state.auth.user.data.entite.id,
    }
  },

  watch: {
    modelValue() {
      this.paramsReceived(this.modelValue)
    },
  },

  mounted() {
    // TODO : Filter api
    if (this.modelValue) {
      this.paramsReceived(this.modelValue)
    }

    this.markers = new MarkerClusterGroup()
  },

  methods: {
    async openModal(modal) {
      if (this.map) {
        this.map.off()
        this.map.remove()
        this.map = null
        this.markers = new MarkerClusterGroup()
      }
      await this.setupLeafletMap('modal-map')
      this.modal[modal] = true
    },
    async closeModal(modal) {
      if (this.map) {
        this.map.off()
        this.map.remove()
        this.map = null
        this.markers = new MarkerClusterGroup()
      }
      await this.setupLeafletMap()
      this.modal[modal] = false
    },
    async getEssais() {
      const response = await this.fetchService.get(this.endpoint, this.filters, false)
      const datas = await response.data
      return datas
    },

    async paramsReceived(event) {
      this.emitter.emit('open-loader')
      this.filters = {
        filters: event,
        limit: 0,
      }

      this.essais = await this.getEssais()
      this.markers.clearLayers()
      this.positions = []

      await this.setupLeafletMap()
      this.emitter.emit('close-loader')
    },
    async setupLeafletMap(elementId = 'map') {
      const LeafIcon = L.Icon.extend({})
      const blueIcon = new LeafIcon({
        iconAnchor: [13, 41],
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
      })

      // eslint-disable-next-line
      if (!this.map) {
        this.map = L.map(elementId, {
          zoomControl: true,
          zoomAnimation: false,
          editable: true,
          dragging: true,
        }).setView(this.center, 13)
        L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
          attribution:
            'données © <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>',
          minZoom: 1,
          maxZoom: 20,
        }).addTo(this.map)

        this.map.zoomControl.setPosition('bottomleft')
      }

      if (this.essais.length > 0) {
        this.positions = this.essais
          .filter((essai) => essai.parcelle?.latitude && essai.parcelle?.longitude)
          .map((essai) => ({
            id: essai.id,
            nom: essai.nom,
            lat: essai.parcelle.latitude,
            lon: essai.parcelle.longitude,
            protocole: essai.protocole,
          }))

        if (this.positions.length > 0) {
          this.positions.forEach((position) => {
            const marker = L.marker([position.lat, position.lon], {
              icon: blueIcon,
              title: position.nom,
              alt: position.nom,
            })
            this.markers.addLayer(marker)

            let popupCard = ''
              + `<p>${position.nom}</p>`

            if (this.entity_id === position.protocole?.entite?.id) {
              popupCard += `Consulter <a href="essai/${position.id}">l'essai</a>`
            }

            marker.bindPopup(popupCard)
          })
          this.map.addLayer(this.markers)
          this.map.fitBounds(this.markers.getBounds())
        }
      } else {
        this.map.setView(this.center, 5)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* MAP WIDGET */

.map-widget {
  position: relative;
  height: 100%;
}

#map,
#modal-map {
  width: 100%;
  height: 100%;
}

.btn-map-fullscreen {
  position: absolute !important;
  left: $gutter-quarter;
  top: $gutter-quarter;
  z-index: +1;
}

::v-deep(.leaflet-pane) {
  z-index: +1;
}

::v-deep(.leaflet-popup-content-wrapper) {
  border-radius: $border-radius-base;
}

::v-deep(.leaflet-popup-content) {
  font-size: $font-size-base;

  p {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

::v-deep(.marker-cluster) {
  background-color: rgba(146, 196, 246, .6);

  >div {
    background-color: rgba(46, 118, 190, .6);
    color: white;
  }
}
</style>
