<template>
  <div class="calendar-widget" :class="{
    'calendar-widget--has-detail-panel': isDetailVisible
  }">
    <div class="calendar-widget-header">
      <Btn icon="chevron-big-left" color="primary" round grow @click="changeMonth('previous')" />
      <h4>{{ getMonthName() }} {{ localYear }}</h4>
      <Btn icon="chevron-big-right" color="primary" round grow @click="changeMonth('next')" />
    </div>

    <div class="calendar-widget-body">

      <!-- Day headers -->
      <template v-for="index in 7" :key="index">
        <div class="calendar-cell calendar-cell--header">
          <span>{{ getDayName(index - 1) }}</span>
        </div>
      </template>

      <!-- Days offset fillers -->
      <template v-for="index in getFirstDayOfMonth(localYear, month)" :key="index">
        <div class="calendar-cell calendar-cell--filler">
          <span></span>
        </div>
      </template>

      <!-- Actual days -->
      <template v-for="day in getDaysInMonth(localYear, month)" :key="day">
        <div class="calendar-cell" :class="{
          'calendar-cell--today': compareDates(`${localYear}-${month}-${day}`),
          'calendar-cell--late': isDayHasTasks(`${localYear}-${month}-${day}`, 'late'),
          'calendar-cell--todo':
            isDayHasTasks(`${localYear}-${month}-${day}`, 'todo')
            || isDayHasTasks(`${localYear}-${month}-${day}`, 'today'),
        }" @click="openDetails(new Date(`${year}-${month}-${day}`))">
          <span>{{ day }}</span>
        </div>
      </template>
    </div>

    <div class="calendar-widget-footer"></div>

    <!-- Detail panel -->
    <div class="detail-panel">
      <div class="detail-panel-header">
        <Btn icon="arrow-left" round grow @click="isDetailVisible = false" />
        <h4 class="detail-panel-title h5">{{ detailDateString }}</h4>
      </div>

      <div class="detail-panel-body">
        <template v-if="dayTasks.length > 0">
          <template v-for="(dayTask, index) in dayTasks" :key="index">
            <div class="detail-panel-item">
              <h5 class="detail-panel-item-title">
                {{ dayTask?.designation ?? dayTask?.type?.designation }}<br />
                <span class="font-size-small color-gray-lighty"> Essai: {{ dayTask.essai.nom }}</span>
              </h5>
              <div class="detail-panel-item-content">
                {{ dayTask.observations ?? 'Aucune observation disponible.' }}
              </div>
              <div class="detail-panel-item-footer">
                <Btn text="Voir la tâche" color="primary" hollow :to="{
                  name: 'essaiExperimentalTask',
                  params: {
                    id: dayTask.essai.id, // TODO
                    tid: dayTask.id
                  },
                }" />
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          Aucun événement n'est prévu pour cette journée.
        </template>
      </div>

      <div class="detail-panel-footer"></div>
    </div>

  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'CalendarWidget',
  components: {
    Btn,
  },

  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    modelValue: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      month: 1,
      monthName: '',
      localYear: this.year,
      isDetailVisible: false,
      detailDateString: '',

      tasks: [],
      dayTasks: [],
      lateTasks: [],
      todoTasks: [],

      filters: { limit: 0 },
    }
  },

  watch: {
    modelValue() {
      this.paramsReceived(this.modelValue)
    },
  },

  mounted() {
    this.getTasks()
    this.getMonthName()

    this.emitter.on('set-year-calendar', this.setYear)

    if (this.modelValue) {
      this.paramsReceived(this.modelValue)
    }
  },

  methods: {
    getTasks() {
      this.fetchService.get('tableau-de-bord/essai/evenement', this.filters, false)
        .then((response) => {
          this.tasks = response.data
            .filter((task) => !task.date_realisation)

          this.lateTasks = this.tasks.filter(
            (task) => this.compareDates(task.date_previsionnelle, null, '<'),
          )
          this.todoTasks = this.tasks.filter(
            (task) => this.compareDates(task.date_previsionnelle, null, '>'),
          )
        })
    },
    // Get a month name (starts from 1)
    getMonthName() {
      const d = new Date(1970, this.month - 1, 1)
      const monthName = d.toLocaleString('default', { month: 'long' })
      return monthName
    },
    // Get a day name by ID (starts from 1)
    getDayName(id) {
      const baseDate = new Date(Date.UTC(2017, 0, 2)) // just a Monday
      baseDate.setDate(baseDate.getDate() + id)
      const dayName = baseDate.toLocaleDateString('default', { weekday: 'short' })
      return dayName
    },
    // Get the number of days in a given month
    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    },
    /**
     * Get the month's first day ID
     * Week start on sunday
     *
     * @param year
     * @param month
     * @returns {number}
     */
    getFirstDayOfMonth(year, month) {
      const getDay = new Date(year, month - 1, 1).getDay()
      return getDay === 0 ? getDay + 6 : getDay - 1
    },
    // Returns true if same day (time doesn't matter)
    compareDates(date1, date2 = null, operator = '===') {
      const ope = {
        '===': (a, b) => a === b,
        '>': (a, b) => a > b,
        '<': (a, b) => a < b,
      }

      return ope[operator](new Date(date1).setHours(0, 0, 0, 0), date2 !== null
        ? new Date(date2).setHours(0, 0, 0, 0)
        : new Date().setHours(0, 0, 0, 0))
    },
    /**
     *
     * @param date
     * @param type
     * @returns {boolean}
     */
    isDayHasTasks(date, type) {
      if (type === 'today') {
        // eslint-disable-next-line
        return this.tasks
          .filter((task) => this.compareDates(task.date_previsionnelle), date)
          .findIndex((task) => this.compareDates(task?.date_previsionnelle, date)) !== -1
      }
      if (type === 'late') {
        // eslint-disable-next-line
        return this.lateTasks.findIndex((lateTask) => this.compareDates(lateTask?.date_previsionnelle, date)) !== -1
      }
      if (type === 'todo') {
        // eslint-disable-next-line
        return this.todoTasks.findIndex((todoTask) => this.compareDates(todoTask?.date_previsionnelle, date)) !== -1
      }
      return false
    },
    // Open the date detail panel
    openDetails(date) {
      this.detailDateString = date.toLocaleDateString('default', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      // eslint-disable-next-line
      this.dayTasks = this.tasks.filter((task) => this.compareDates(task?.date_previsionnelle, date))
      this.isDetailVisible = true
    },
    /**
     * Change month
     * @param direction previous, next
     */
    changeMonth(direction) {
      if (direction === 'next') {
        this.month += 1

        if (this.month > 12) {
          this.month -= 12
          this.localYear += 1
        }
      } else if (direction === 'previous') {
        this.month -= 1

        if (this.month <= 0) {
          this.month += 12
          this.localYear -= 1
        }
      }
    },
    async paramsReceived(event) {
      // TODO : Filters api
      /* this.filters = {
        filters: event,
        limit: 0,
      }

      this.essais = await this.getTasks() */
      if ('annee' in event && event.annee !== null) {
        this.fetchService.get(`dictionnaire/referentiel/${this.helperService.getReferentialByUid(
          'anneerecolte',
        )}/valeur/${event.annee}`, {
          limit: 1,
        }).then((response) => {
          // eslint-disable-next-line
          event['annee'] = response.data.valeur
          this.setYear(event)
        })
      }
    },
    setYear(event) {
      if ('annee' in event && event.annee !== null) {
        this.month = this.toNumber(new Date().getMonth()) + 1
        const [y] = event.annee.split(',').sort((a, b) => a - b)
        this.localYear = this.toNumber(y)
      } else {
        this.month = this.toNumber(new Date().getMonth()) + 1
        this.localYear = this.toNumber(new Date().getFullYear())
      }
    },
    toNumber(string) {
      return parseInt(string, 10)
    },
  },
}
</script>

<style lang="scss" scoped>
/* CALENDAR WIDGET */

.calendar-widget {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.calendar-widget-header,
.detail-panel-header {
  padding: $gutter-quarter;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $gutter-quarter;
  color: $color-primary;

  >h4 {
    margin-bottom: 0;
  }
}

.calendar-widget-header {}

.calendar-widget-body {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: $gutter-eighth;
  // align-items: center;
  padding: $gutter-half;
}

.calendar-cell {
  // text-align: center;
  // background: #eee;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-small;
  font-weight: $font-weight-semibold;
  user-select: none;
}

.calendar-cell--header {
  text-transform: capitalize;
  color: $color-gray;
  font-weight: $font-weight-normal;
}

.calendar-cell--today,
.calendar-cell--late,
.calendar-cell--todo {
  >span {
    z-index: +1;
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    @include size(2.8rem);
    transition: all 0.25s;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.calendar-cell--today {
  &::before {
    border-radius: 50%;
    box-shadow: 0 0 0 3px white,
      0 0 0 5px $color-gray-light;
  }
}

.calendar-cell--late,
.calendar-cell--todo {
  color: white;
  cursor: pointer;

  @include hocus() {
    &::before {
      @include shadow(1);
    }
  }

  &:active {
    left: 1px;
    top: 1px;
  }
}

.calendar-cell--late {

  &::before {
    border-radius: $border-radius-small;
    background-color: $color-error;
  }

  @include hocus() {
    &::before {
      background-color: darken($color-error, 15%);
    }
  }

  &:active {
    &::before {
      background-color: darken($color-error, 30%);
    }
  }
}

.calendar-cell--todo {

  &::before {
    border-radius: 50%;
    background-color: $color-primary;
  }

  @include hocus() {
    &::before {
      background-color: $color-primary-dark;
    }
  }

  &:active {
    &::before {
      background-color: $color-primary-darker;
    }
  }
}

.detail-panel {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%);
  background-color: white;
  z-index: +1;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: all 0.25s;

  .calendar-widget--has-detail-panel & {
    transform: translateX(0);
  }
}

.detail-panel-header {
  border-bottom: 1px solid $color-gray-lighter;
}

.detail-panel-title {
  flex-grow: 1;
  text-transform: capitalize;
  text-transform: uppercase;
}

.detail-panel-body {
  flex-grow: 1;
  padding: $gutter-half;
  overflow-y: auto;
  @include custom-scrollbar();
}

.detail-panel-item {
  margin-bottom: $gutter-half;
}

.detail-panel-item-title {
  margin-bottom: $gutter-quarter;
}

.detail-panel-item-content {
  margin-bottom: $gutter-quarter;
  // font-size: $font-size-small;
}

.detail-panel-item-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
