<template>
  <div class="trials-widget">

    <table>
      <thead>
        <tr>
          <template v-if="!isUnion">
            <th>Numéro</th>
            <th>Nom</th>
          </template>
          <template v-else>
            <th>Désignation</th>
            <th>Entité</th>
            <th>Numéro</th>
            <th class="cell-validation">Validation agro</th>
          </template>
        </tr>
      </thead>
      <tbody v-if="essais.length > 0">
        <tr v-for="(essai, index) in essais" :key="index">
          <template v-if="!isUnion">
            <td class="cell-number">{{ essai.numero ?? '—' }}</td>
            <td>
              <router-link :to="{ name: 'essai', params: { id: essai.id } }" v-ripple>
                {{ essai.nom }}
              </router-link>
            </td>
          </template>
          <template v-else>
            <td>
              <template v-if="entity_id !== essai.protocole?.entite?.id">{{ essai.nom }}</template>
              <router-link v-else :to="{ name: 'essai', params: { id: essai.id } }" v-ripple>
                {{ essai.nom }}
              </router-link>
            </td>
            <td>{{ essai.protocole.entite.nom }}</td>
            <td class="cell-number">{{ essai.numero ?? '—' }}</td>
            <td class="cell-validation" :class="essai.validation_class">
              <SvgIcon v-if="essai.validation_agro" name="check-circle" size="lg"/>
              <SvgIcon v-else name="times-circle" size="lg"/>
            </td>
          </template>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" class="td-empty-message"> Aucun essai n'a été trouvé</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import SvgIcon from '../base/SvgIcon.vue'

export default {
  name: 'TrialsWidget',

  components: {
    SvgIcon,
  },

  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
    isUnion: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      endpoint: 'tableau-de-bord/essai',
      essais: [],
      filters: null,
      entity_id: this.$store.state.auth.user.data.entite.id,
    }
  },

  watch: {
    modelValue() {
      this.paramsReceived(this.modelValue)
    },
  },

  mounted() {
    if (this.modelValue) {
      this.paramsReceived(this.modelValue)
    }
  },

  methods: {
    async getEssais() {
      const response = await this.fetchService.get(this.endpoint, this.filters, false)
      const datas = await response.data
      return datas
    },

    async getValidation(id) {
      const response = await this.fetchService.get(`essai/${id}/fiche/validation`, null, false)
      const datas = await response.data
      const fiche = datas.pop()
      const isAgro = fiche?.validation_agro ?? false
      return isAgro
    },

    async paramsReceived(event) {
      this.filters = {
        filters: event,
        limit: 0,
        sort: 'nom.ASC',
      }

      this.essais = await this.getEssais()
      this.essais.forEach(async (essai, index) => {
        const isAgro = await this.getValidation(essai.id)
        const vClass = isAgro ? 'color--success' : 'color--error'
        if (this.essais[index]) {
          this.essais[index].validation_agro = isAgro
          this.essais[index].validation_class = vClass
        }
      })

      this.$emit('widget-emits-title', {
        title: `${this.essais.length} essais(s) en cours`,
        widget: 'trials',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* TRIALS WIDGET */
// /!\ Same CSS as `src\components\widget\ProtocolsWidget.vue` /!\

.color--error {
  color: $color-error
}

.color--success {
  color: $color-success
}

.trials-widget {
  padding: $gutter-half;

  table {
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    cell-spacing: 0;
    @include h-margin(- $gutter-quarter);
    line-height: $line-height-small;
  }

  thead {
    position: sticky;
    top: 0;
    font-weight: $font-weight-bold;
    background-color: white;

    th {
      border-bottom: 1px solid $color-gray-lighter;
    }
  }

  tbody {
    // overflow-y: auto;
  }

  td,
  th {
    padding: ($gutter-quarter * 1.5) $gutter-quarter;
    text-align: left;

    &.td-empty-message {
      padding: $gutter-half;
      font-style: italic;
    }
  }

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px solid $color-gray-lighter;

        .widget--color-primary & {
          border-color: $color-primary-light;
        }
      }
    }
  }

  th {
    &.grow {
      width: 100%;
    }
  }
}

.cell-validation {
  text-align: center !important;
}

.cell-number {
  font-family: $font-family-monospace;
}

</style>
