<template>
  <template v-if="reservations.length">
    <div class="notation-card-wrapper" v-for="reservation in reservations" :key="reservation.id">
      <NotationCard :reservation="reservation" isInDashboard isOwner />
    </div>
    <ProgressModal />
  </template>
  <template v-else>
    <p>Il n'y a pas de notation en cours</p>
  </template>
</template>

<script>
import NotationCard from '@/components/base/NotationCard.vue'
import ProgressModal from '@/views/componentsViews/notation/ProgressModal.vue'

export default {
  name: 'NotationsWidget',
  components: {
    NotationCard,
    ProgressModal,
  },

  emits: ['widget-emits-title'],

  data() {
    const statesUids = [
      'RESERVE',
      'ENCOURS',
      'ATTENTESYNCHRO',
      'ERREURSYNCHRO',
    ]

    return {
      statesUids,
      reservations: [],
    }
  },
  mounted() {
    this.getReservations()
    this.emitter.on('notation-refresh', this.getReservations)
  },
  methods: {
    getReservations() {
      let reservations = this.notationService.getFromLocalStorage('reservations')
      reservations = this.filterReservation(reservations)

      if (reservations.length === 0) {
        this.reservations = []
        this.$emit('widget-emits-title', { title: '0 notations en cours', widget: 'notation' })
      }

      if (navigator.onLine) {
        reservations.forEach((item) => {
          this.fetchService.handleErrors = false

          this.fetchService.get(`essai/${item.essai.id}`, null, false).then(() => {
            this.fetchService.get(
              `essai/${item.essai.id}/evenement/${item.evenement.id}`,
              null, false,
            ).then(() => {
              this.fetchService.get(
                `essai/${item.essai.id}/evenement/${item.evenement.id}/fiche/notation/${item.notation.id}/reservation/${item.id}`,
                null, false,
              ).then((response) => {
                if (response.data.etat.uid === 'REALISE') {
                  this.notationService.removeItem('reservations', item.id)
                  reservations = this.notationService.getFromLocalStorage('reservations')
                  reservations = this.filterReservation(reservations)
                }
                this.reservations = reservations
                const reservationsLength = this.reservations.length
                this.$emit('widget-emits-title', { title: `${reservationsLength} notations en cours`, widget: 'notation' })
              }, () => {
                this.notationService.removeItem('reservations', item.id)
                reservations = this.notationService.getFromLocalStorage('reservations')
                reservations = this.filterReservation(reservations)
              })
            }, () => {
              this.notationService.removeItem('reservations', item.id)
              reservations = this.notationService.getFromLocalStorage('reservations')
              reservations = this.filterReservation(reservations)
            })
          }, () => {
            this.notationService.removeItem('reservations', item.id)
            reservations = this.notationService.getFromLocalStorage('reservations')
            reservations = this.filterReservation(reservations)
          })
        })
      } else {
        this.reservations = reservations
        const reservationsLength = this.reservations.length
        this.$emit('widget-emits-title', { title: `${reservationsLength} notations en cours`, widget: 'notation' })
      }
    },
    filterReservation(reservations) {
      // eslint-disable-next-line no-param-reassign
      reservations = reservations.filter((resa) => this.statesUids.includes(resa?.etat?.uid))
      // eslint-disable-next-line no-param-reassign
      reservations = reservations.filter(
        (resa) => resa.utilisateur.id === this.$store.state.auth.user.data.id,
      )
      return reservations
    },
  },

}
</script>
<style lang="scss" scoped>
.notation-card-wrapper {
  &:not(:last-child) {
    margin-bottom: $gutter;
  }
}
</style>
