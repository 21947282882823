<template>
  <div class="summary-widget">

    <div class="summary-widget-body">
      <div class="summary-trials-group summary-trials-group--themes" :class="{ 'active': activeGroup === 'themes' }">
        <Btn class="trials-group-title h4" block icon="chevron-big-down" iconAfter text="Essais par thématique"
          @click="toggleGroup('themes')" />
        <div v-if="summary.thematiques && activeGroup === 'themes'" class="trials-group-stats">
          <div v-for="(thematique, index) in summary.thematiques" :key="index" class="trials-group-stat"
            :class="{ 'trials-group-stat--zero': thematique === 0 }">
            <div class="trials-group-stat-label">{{ index }}</div>
            <div class="trials-group-stat-separator"></div>
            <div class="trials-group-stat-value">{{ thematique }}</div>
          </div>
        </div>
      </div>

      <div class="summary-trials-group summary-trials-group--cultures" :class="{ 'active': activeGroup === 'cultures' }">
        <Btn class="trials-group-title h4" block icon="chevron-big-down" iconAfter text="Essais par culture"
          @click="toggleGroup('cultures')" />
        <div v-if="summary.cultures && activeGroup === 'cultures'" class="trials-group-stats">
          <div v-for="(culture, index) in summary.cultures" :key="index" class="trials-group-stat"
            :class="{ 'trials-group-stat--zero': culture === 0 }">
            <div class="trials-group-stat-label">{{ index }}</div>
            <div class="trials-group-stat-separator"></div>
            <div class="trials-group-stat-value">{{ culture }}</div>
          </div>
        </div>
      </div>

      <div class="summary-trials-group summary-trials-group--sub-themes"
        :class="{ 'active': activeGroup === 'sub-themes' }">
        <Btn class="trials-group-title h4" block icon="chevron-big-down" iconAfter text="Essais par sous-thème"
          @click="toggleGroup('sub-themes')" />
        <div v-if="summary.sous_thematiques && activeGroup === 'sub-themes'" class="trials-group-stats">
          <div v-for="(sub, index) in summary.sous_thematiques" :key="index" class="trials-group-stat"
            :class="{ 'trials-group-stat--zero': sub === 0 }">
            <div class="trials-group-stat-label">{{ index }}</div>
            <div class="trials-group-stat-separator"></div>
            <div class="trials-group-stat-value">{{ sub }}</div>
          </div>
        </div>
      </div>

    </div>

    <div class="summary-widget-footer" v-if="activeGroup === 'none'">

      <div class="summary-all-trials">
        <h4>Essais</h4>
        <div v-if="summary.bpe >= 0" class="summary-all-trial">
          <div class="summary-all-trial-label">BPE</div>
          <div class="summary-all-trial-value">{{ summary.bpe }}</div>
        </div>
        <div v-if="summary.bpe_sidep >= 0" class="summary-all-trial">
          <div class="summary-all-trial-label">BPE Sidep</div>
          <div class="summary-all-trial-value">{{ summary.bpe_sidep }}</div>
        </div>
        <div v-if="summary.ab >= 0" class="summary-all-trial">
          <div class="summary-all-trial-label">AB</div>
          <div class="summary-all-trial-value">{{ summary.ab }}</div>
        </div>
      </div>

      <div class="summary-all-counters">
        <div class="summary-all-counter">
          <div class="summary-all-counter-label">Micro-parcelles</div>
          <div v-if="summary.microparcelles >= 0" class="summary-all-counter-value">
            <Counter :value="summary.microparcelles" />
          </div>
        </div>
        <div class="summary-all-counter">
          <div class="summary-all-counter-label">Modalités</div>
          <div v-if="summary.modalites >= 0" class="summary-all-counter-value">
            <Counter :value="summary.modalites" />
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Counter from '@/components/base/Counter.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'SummaryWidget',
  components: {
    Counter,
    Btn,
  },

  props: {
    name: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: [Object, String],
      default: null,
    },
  },

  data() {
    return {
      summary: {},
      filters: {},
      activeGroup: 'none',
    }
  },

  watch: {
    modelValue() {
      this.paramsReceived()
    },
  },

  mounted() {
    this.paramsReceived()
  },

  methods: {
    async getSummary() {
      let response = null
      if (this.modelValue) {
        response = await this.fetchService.get(`tableau-de-bord/essai/chiffres/${this.modelValue}`, null, false)
      } else {
        response = await this.fetchService.get('tableau-de-bord/essai/chiffres', null, false)
      }

      const datas = await response.data
      return datas
    },

    async paramsReceived() {
      this.summary = await this.getSummary()
    },

    toggleGroup(group) {
      if (this.activeGroup === group) {
        this.activeGroup = 'none'
      } else {
        this.activeGroup = group
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* USERS WIDGET */

.summary-widget {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: $font-weight-bold;
}

.summary-widget-body,
.summary-widget-footer {
  padding: $gutter-half;
}

.summary-widget-body {
  overflow-y: auto;
  @include custom-scrollbar();
  padding-bottom: $gutter-quarter;
  flex-grow: 1;
}

.summary-widget-footer {
  display: flex;
  align-items: center;
  gap: $gutter-half;
  padding-top: $gutter-quarter;

  >* {
    flex-basis: 50%;
    max-width: 50%;
  }
}

// SUMMARY TRIALS GROUPS

.summary-trials-group {
  border-radius: $border-radius-base;
  color: white;

  &:not(:last-child) {
    margin-bottom: $gutter-quarter;
  }
}

.summary-trials-group--themes {
  background-color: $color-primary-light;
  color: black;
}

.summary-trials-group--cultures {
  background-color: $color-primary-lighty;
}

.summary-trials-group--sub-themes {
  background-color: $color-primary;
}

.trials-group-title {
  margin-bottom: 0;
  justify-content: space-between;
  color: inherit;
  @include v-padding($gutter-half);

  @include hocus() {
    color: inherit !important;
  }

  ::v-deep(.icon) {
    transition: all 0.25s;
  }

  .active & {
    ::v-deep(.icon) {
      transform: rotate(180deg);
    }
  }
}

.trials-group-stats {
  padding: $gutter-half;
  border-top: 1px solid transparentize(white, 0.5);

  .summary-trials-group--themes & {
    border-top: 1px solid transparentize($color-primary, 0.5);
  }
}

.trials-group-stat {
  max-width: 24rem;
  display: flex;
  align-items: center;
  gap: $gutter-quarter;

  &:not(:last-child) {
    margin-bottom: $gutter-eighth;
  }

  &.trials-group-stat--zero {
    opacity: 0.5;
  }
}

.trials-group-stat-label {
  line-height: $line-height-small;
  font-weight: $font-weight-semibold;
}

.trials-group-stat-value {}

.trials-group-stat-separator {
  flex-grow: 1;
  border-bottom: 2px dotted;
  transform: translateY(0.4rem);
}

// SUMMARY FOOTER

.summary-all-trials {
  padding: $gutter-half;
  background-color: $color-gray-lightest;
  border-radius: $border-radius-base;

  >h4 {
    margin-bottom: $gutter-quarter;
  }
}

.summary-all-counters {}

.summary-all-trial,
.summary-all-counter {
  display: flex;
  gap: $gutter-quarter;
  justify-content: space-between;
}

.summary-all-trial-label {
  font-weight: $font-weight-semibold;
}

.summary-all-counter {
  align-items: center;

  &:not(:last-child) {
    margin-bottom: $gutter-quarter;
  }
}

.summary-all-counter-label {
  line-height: $line-height-small;
}

.summary-all-counter-value {
  flex-basis: 5rem;
  flex-shrink: 0;
}
</style>
