<template>
  <div class="counter">
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: 'Counter',

  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
/* COUNTER */

.counter {
  height: 3.8rem;
  line-height: 3.8rem;
  text-align: center;
  font-size: $font-size-bigger;
  font-weight: $font-weight-bold;
  border-radius: $border-radius-base;
  @include shadow(1, $color-gray-dark);
}
</style>
